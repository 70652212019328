import React from "react";

import "rapidoc";
import "./styles.css";

export default function App() {
  return (<rapi-doc style={{ height: '100vh', width: '100%' }}
                    spec-url="https://openapi.stack2.qadev.singlewire.com/api/openapi.json"
                    persist-auth="true"
                    show-header="false"
                    render-style="focused"
                    theme="dark"
                    bg-color-="#2E2E2E"
                    text-color="#FFFFFF"
                    nav-text-color="#FFFFFF"
                    header-color="#545456"
                    primary-color="#F8C630"
                    allow-advanced-search="true"
                    allow-search="false"
                    sort-endpoints-by="method"
                    fill-request-fields-with-example="false"
                    on-nav-tag-click="show-description">
            <img slot="nav-logo" src="images/singlewire-logo.png"/>
          </rapi-doc>);
}
